.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background: #f8f9fa;
    color: #343a40;
  }
  
  .not-found-title {
    font-size: 10rem;
    margin-bottom: 1rem;
  }
  
  .not-found-description {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .not-found-link {
    text-decoration: none;
    font-size: 1.2rem;
    color: #007bff;
    border: 2px solid #007bff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .not-found-link:hover {
    background-color: #007bff;
    color: #fff;
  }
  