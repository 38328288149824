.ant-menu-item-selected {
  background-color: #ff69b4 !important;
  color: #fff !important;
}

.ant-menu-item:hover {
  background-color: #ffb6c1;
}

.ant-menu-item {
  background-color: #ffb6c1;
  color: #000;
}
